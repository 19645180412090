import './Tokeneconomics.css';
import img from "../img/MEMENANCE_Website (600 x 600 piksel).svg"
import img2 from "../img/economics.svg";
function Tokeneconomics() {

    return (
        <div className="tokeneconomics">
            <div className="tokeneconomicstext">
                <h1 style={{}}>$manceconomıc</h1>
                <h1>TOTAL SUPPLY: 1,000,000,000 $MANCE</h1>
            </div>
            <div className="tokeneconomicscenter">
                <div style={{display:"flex", alignItems:"center", justifyContent:"center"}}>
                    <img src={img2} alt="" style={{width:"70%"}}/>
                </div>
                <div style={{display:"flex", alignItems:"center", justifyContent:"center"}}>
                    <ul style={{textAlign:"center"}}>
                        <li className="economicsitem">Marketing 10%</li>
                        <li className="economicsitem">Airdrop 30%</li>
                        <li className="economicsitem">Liquidity 25%</li>
                        <li className="economicsitem">Exchanges 15%</li>
                        <li className="economicsitem">Team 5%</li>
                        <li className="economicsitem">Ecosystem 15%</li>
                    </ul>
                </div>
            </div>

            <a href="https://bscscan.com/token/0xc3d6889F939A4ddcF5af7B546b7F97765F0F005a" target="_blank" style={{textDecoration:'none', marginTop:"5%"}}>
                <div className="button">
                        $mance address
                </div>
            </a>
            <div className="tokeneconomicsBottom">
                <img className="tokeneconomicsimg" src={img} alt=""/>
                <h1 className="tokeneconomicsh1">
                    THE CAT OF BINANCE <br/>
                    Who said this year is the year of the dog? This year is the year of the CAT, the YEAR OF MEMENANCE!
                </h1>
            </div>
        </div>
    );
}

export default Tokeneconomics;
