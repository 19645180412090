import img from "../img/miniapp.svg"
import "../Pages/Miniapp.css"
function Miniapp() {
    return(
        <div className="miniapp">
            <div style={{display:"flex", alignItems:"center", justifyContent:"center"}}>
                <img src={img} alt="" style={{width:"90%"}}/>
            </div>
            <div style={{color:"#EFCB4A", textAlign:"center"}} className="miniapptext">
                <h2>MEMENANCE Airdrop Game is Live Now!</h2><br/>
                <h2>PLAY GAMES, COLLECT POINTS, EARN </h2><br/>
                <h2 className="miniapph2">$MANCE!</h2>
                <div  className="introbutton" >
                    <a href="https://t.me/Memenance_Game_bot" className="introbtn" >Play</a>
                </div>
            </div>

        </div>
    )
}
export default Miniapp;