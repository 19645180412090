import './Intro.css';
import img from "../img/introimg.svg"

function App() {
    return (
        <div className="intro">
            <div  className="introbutton" >
                 <a href="https://t.me/Memenance_Game_bot" className="introbtn" >Airdrop Game</a>
                </div>
            <div className="introimgdiv">
                <img className="introimg" src={img} alt=""/>
            </div>
            <div>
                <p className="marquee">
                    <span className="introspan">$MANCE - $MANCE - $MANCE - $MANCE - $MANCE - $MANCE - $MANCE - $MANCE - $MANCE - $MANCE - $MANCE - $MANCE - $MANCE;</span>
                </p>

            </div>
        </div>
    );
}

export default App;
