import './Navbar.css';
import img from "../img/MEMENANCE_Website (250 x 250 piksel).svg"
import Intro from "./Intro";
import About from "./About";
import Project from "./Project";
import Tokeneconomics from "./Tokeneconomics";
import Roadmap from "./Roadmap";
import Faq from "./Faq";
import {useRef} from "react";
import {HiMiniBars3BottomRight} from "react-icons/hi2";
import {IoCloseSharp} from "react-icons/io5";
import Footer from "./Footer";
import Miniapp from "./Miniapp";
function Navbar() {

    const navRef = useRef();
    const showNavbar = ()=>{
        navRef.current.classList.toggle("responsive_nav")
    }
    
    return (
        <div>
            <div className="navbar">
                <div className="navbarleft">
                    <div className="navbarimgdiv">
                    <img className="navbarimg" src={img} alt=""/>
                    </div>
                    <button className="nav-btn" onClick={showNavbar}>
                        <HiMiniBars3BottomRight style={{ fontSize:'45px'}}/>
                    </button>
                </div>
                <div className="navbarright" ref={navRef}>
                    <a href="#airdropgame" className="navbarh2" style={{textDecoration:'none'}}><h2 >GAME</h2></a>
                    <a href="#about" className="navbarh2" style={{textDecoration:'none'}}><h2 >ABOUT</h2></a>
                    <a href="#tokeneconomics" className="navbarh2" style={{textDecoration:'none'}}><h2 >$MANCECONOMIC</h2></a>
                    <a href="#roadmap" className="navbarh2" style={{textDecoration:'none'}}><h2 >MANCEMAP</h2></a>
                    <a href="#faq" className="navbarh2" style={{textDecoration:'none'}}><h2 >FAQ</h2></a>
                    <a href="#contact" className="navbarh2" style={{textDecoration:'none'}}><h2 >$MANCEMEDIA!</h2></a>
                    <button className="nav-btn-close nav-close-btn" onClick={showNavbar}>
                        <IoCloseSharp style={{fontSize:'35px'}}/>
                    </button>
                </div>
            </div>

            <div>
                <Intro/>
            </div>
            <div id="airdropgame">
                <Miniapp/>
            </div>
            <div id="about">
                <About/>
            </div>
            <div>
                <Project/>
            </div>
            <div id="tokeneconomics">
                <Tokeneconomics/>
            </div>
            <div id="roadmap">
                <Roadmap/>
            </div>
            <div id="faq">
                <Faq/>
            </div>
            <div id="contact">
                <Footer/>
            </div>

        </div>
    );
}

export default Navbar;
