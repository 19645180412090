import './Project.css';
import img5 from "../img/MEMENANCE_Website (6).svg"


function Project() {
    return (
        <div className="project">
            <div className="projectimg">
                <img className="projeboxcenter" src={img5} alt=""/>
            </div>
            <div className="projectbutton">
                <h1 className="projecta">for more, ask on</h1>
                <a href="https://t.me/memenancechat" target="_blank" className="projecta1"><h1>T E L E G R A M</h1></a>
            </div>
        </div>
    );
}

export default Project;
